// extracted by mini-css-extract-plugin
export var answer = "s-module--answer--8fd7b";
export var blue = "s-module--blue--2ee26";
export var bold = "s-module--bold--92f33";
export var collapse = "s-module--collapse--809d7";
export var contentAnimation = "s-module--contentAnimation--08f5b";
export var expande = "s-module--expande--c49ed";
export var faqContainer = "s-module--faqContainer--3ff6e";
export var footerContainer = "s-module--footerContainer--a8c53";
export var mb_15 = "s-module--mb_15--dfb8b";
export var mb_30 = "s-module--mb_30--caefc";
export var mb_45 = "s-module--mb_45--d2687";
export var mb_60 = "s-module--mb_60--e6fb7";
export var ml_45 = "s-module--ml_45--9844e";
export var ml_60 = "s-module--ml_60--909e8";
export var myTableList = "s-module--myTableList--3b1f9";
export var nftAvatarsContainer = "s-module--nftAvatarsContainer--df890";
export var nftAvatarsContent = "s-module--nftAvatarsContent--a9a53";
export var numberList = "s-module--numberList--ca097";
export var question = "s-module--question--bab83";
export var questionContainerClosed = "s-module--questionContainerClosed--80cfa";
export var questionContainerOpened = "s-module--questionContainerOpened--9e0d1";
export var row = "s-module--row--4aa26";
export var tableContainer = "s-module--tableContainer--65871";