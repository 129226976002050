import React from "react"

import Layout from "../../layout/default"
import Seo from "../../components/seo"
import NFTAvatarsPage from '../../components/TokenEconomy/nftAvatarsPage';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="NFT Avatars" />
    <NFTAvatarsPage location={location}/>
  </Layout>
)

export default IndexPage
