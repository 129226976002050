import React, { Fragment } from 'react';
import * as s from './s.module.css';
import cx from 'classnames';
import RoundButton from '../../RoundButton';

import Title from '../../Title';
import { Link } from "@reach/router"
import { navigate } from 'gatsby';

interface Props {
    title: string,
    location: any,
}

class TokenEconomyHeader extends React.Component<Props> {
    render() {
        const { title, location } = this.props;
        return (
            <Fragment>
                <Title>{title}</Title>
                <div className={cx(s.tabBtnsContainer)}>
                    <div className={s.btnsBlock}>
                        <RoundButton active={location.pathname.includes('/tokeneconomy/overview')} onClick={() => navigate('/tokeneconomy/overview')}>Overview</RoundButton>
                        <RoundButton active={location.pathname.includes('/tokeneconomy/vpp-token')} onClick={() => navigate('/tokeneconomy/vpp-token')}>VPP Token</RoundButton>
                    </div>
                    <div className={s.btnsBlock}>
                        <RoundButton active={location.pathname.includes('/tokeneconomy/gold-token')} onClick={() => navigate('/tokeneconomy/gold-token')}>$GOLD Token</RoundButton>
                        <RoundButton active={location.pathname.includes('/tokeneconomy/nft-avatars')} onClick={() => navigate('/tokeneconomy/nft-avatars')}>NFT Avatars</RoundButton>
                    </div>
                </div>
                <Link to="/tokeneconomy/staking">
                    <div className={cx(s.blueButton, {[s.blueButtonEnabled]: location.pathname.includes('/tokeneconomy/staking')})}/>
                </Link>
            </Fragment>
        )
    }
}

export default TokenEconomyHeader;