import React from 'react';
import Collapsible from 'react-collapsible';
import * as s from './s.module.css';
import cx from 'classnames';

class NftAvatars extends React.Component {
    render() {
        return (
            <section className={s.nftAvatarsContainer}>
                <div className={s.nftAvatarsContent}>
                    <p>
                        <span className={s.bold}>Virtue</span> is conducting a Genesis Mint of NFT avatars that can be used in the following ways:
                    </p>
                    <div  className={cx(s.mb_45,s.tableContainer, s.ml_60)}>
                        <div className={s.myTableList}>
                            <div className={s.row}>
                                <div className={s.numberList}>
                                    <p className={s.blue}>
                                        Chip Bonus:
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        Players who whitelist/purchase our NFT avatars will receive a one-time Chips bonus (2,000) per avatar. Chips bonus will help them better compete in our Weekly Challenges.
                                    </p>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.numberList}>
                                    <p className={s.blue}>
                                    Access to Virtue Discord NFT Group:
                                    </p>
                                </div>
                                <div>
                                    <p>
                                    Players who whitelist/purchase our NFT avatars will get access to private Discord with other NFT owners.
                                    <br/>
                                    Owners of rare Virtue NFTs will get access to an exclusive Discord group with Phil Ivey and other brand ambassadors.
                                    </p>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.numberList}>
                                    <p className={s.blue}>
                                        Weekly $GOLD Earnings Boost:
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        Players with active NFT avatars will earn a multiplier on their weekly earnings based on their finish on the leaderboard (coming later this year).
                                    </p>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.numberList}>
                                    <p className={s.blue}>
                                        Access Pass to Virtue’s Metaverse Poker:
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        NFT avatars will be portable across Virtue real-money and play-to-earn games into our Metaverse poker game (coming later this year).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.footerContainer}>
                        <p>
                            Cost:
                        </p>
                        <p>
                            Genesis Mint of Virtue NFT will cost 150 $GOLD per avatar.<br/> Our upcoming Genesis Mint of NFT avatars will be 500 units of hand-designed unique art characters among five rarities that will change the utility players receive from their NFT.
                        </p>
                        <p>
                            Whitelisting:
                        </p>
                        <p>
                            Whitelisting will be open from June 3rd until sold out.<br/>Players who want to get Virtue NFT need to go to the Cashier and redeem $GOLD for an NFT avatar. Players will receive a confirmation email once added to the whitelist. Players will receive a one-time Chips bonus (2,000) credited to their account.
                        </p>
                        <p>
                            NFT FAQ:
                        </p>
                    </div>

                    <div className={s.faqContainer}>
                        <Collapsible className={s.questionContainerClosed} openedClassName={s.questionContainerOpened}
                            trigger={
                                <div className={s.question}>
                                    <span>How do I reserve an NFT avatar?</span>
                                    <span className={s.expande}>+</span>
                                    <span className={s.collapse}>-</span>
                                </div>
                            }>
                            <div className={s.answer}>
                                <ul>
                                    <li>Log-in to the Virtue application</li>
                                    <li>Go to the Promotions </li>
                                    <li>Navigate to the $GOLD redemption page </li>
                                    <li>Click “redeem for NFT” </li>
                                    <li>That’s it! </li>
                                </ul>
                                <p>Players will receive an email confirming they’ve successfully reserved their NFT avatar. </p>
                                <p>Players will be able to redeem their $GOLD for an NFT starting on June 3rd, 2022 - and NFT redemption will be open until the 500 genesis NFTs are sold out. </p>
                            </div>
                        </Collapsible>

                        <Collapsible className={s.questionContainerClosed} openedClassName={s.questionContainerOpened}
                            trigger={
                                <div className={s.question}>
                                    <span>Can I purchase multiple NFT avatars?</span>
                                    <span className={s.expande}>+</span>
                                    <span className={s.collapse}>-</span>
                                </div>
                            }>
                            <div className={s.answer}>
                                <p>
                                    Yes, players can purchase up to a maximum of 5 NFT avatars. If a player purchases 5 NFTs and uses all 5 they will receive up to 10,000 bonus chips. 
                                </p>
                            </div>
                        </Collapsible>

                        <Collapsible className={s.questionContainerClosed} openedClassName={s.questionContainerOpened}
                            trigger={
                                <div className={s.question}>
                                    <span>I don’t have 150 $GOLD, can I still buy an NFT avatar?</span>
                                    <span className={s.expande}>+</span>
                                    <span className={s.collapse}>-</span>
                                </div>
                            }>
                            <div className={s.answer}>
                                <p>
                                    Virtue is launching our next “$GOLD Bankroll Builder” promotion on June 6th 2022 with over 30,000 $GOLD in weekly prizes. 
                                    Players who do not have enough $GOLD can still earn it by competing in our Weekly Leaderboards, Challenges, Drawings and Multi-step tournaments. 
                                    Once they collect enough $GOLD they can redeem it for an NFT (provided they are not sold out). 
                                </p>
                            </div>
                        </Collapsible>

                        <Collapsible className={s.questionContainerClosed} openedClassName={s.questionContainerOpened}
                            trigger={
                                <div className={s.question}>
                                    <span>When will I receive my NFT avatar(s)?</span>
                                    <span className={s.expande}>+</span>
                                    <span className={s.collapse}>-</span>
                                </div>
                            }>
                            <div className={s.answer}>
                                <p>
                                    We anticipate launching our full collection of NFTs prior to the end of summer.
                                </p>
                            </div>
                        </Collapsible>

                        <Collapsible className={s.questionContainerClosed} openedClassName={s.questionContainerOpened}
                            trigger={
                                <div className={s.question}>
                                    <span>How will I claim my NFT?</span>
                                    <span className={s.expande}>+</span>
                                    <span className={s.collapse}>-</span>
                                </div>
                            }>
                            <div className={s.answer}>
                                <p>
                                    Those who reserve their NFT avatar will be able to navigate to the promotions section within the client 
                                    and redeem $GOLD for an NFT avatar whitelist reservation.
                                </p>
                            </div>
                        </Collapsible>

                        <Collapsible className={s.questionContainerClosed} openedClassName={s.questionContainerOpened}
                            trigger={
                                <div className={s.question}>
                                    <span>Will I receive my chips bonus prior to claiming my NFT avatar?</span>
                                    <span className={s.expande}>+</span>
                                    <span className={s.collapse}>-</span>
                                </div>
                            }>
                            <div className={s.answer}>
                                <p>
                                    Yes, all players who purchase their NFT avatar(s) in the Virtue Poker application will be credited with their chips bonus on June 6th 2022. 
                                    If a player purchases an NFT avatar after June 6th - their chips bonus will be credited to their account within 72 hours. 
                                </p>
                            </div>
                        </Collapsible>

                        <Collapsible className={s.questionContainerClosed} openedClassName={s.questionContainerOpened}
                            trigger={
                                <div className={s.question}>
                                    <span>After I receive it, how can I use the NFT avatar in the Virtue application?</span>
                                    <span className={s.expande}>+</span>
                                    <span className={s.collapse}>-</span>
                                </div>
                            }>
                            <div className={s.answer}>
                                <p>Players will be required to stake their NFT avatar prior to redeeming their $GOLD earned during our $GOLD Bankroll Builder promotion.</p>
                            </div>
                        </Collapsible>
                    </div>
                </div>
            </section>
        );
    }
}

export default NftAvatars;
